 const SliderData = [
    {
    id: 1,
    title: "50% Off For Your First Shopping",
    desc: "Best online store to buy right now, fashion trends that matter. Reviews & clothes: Women's, Men's, Old, Young, children.",
    cover: "./images/slider/slide-1.png",
  },
  {
    id: 2,
    title: "50% Off For Your First Shopping",
    desc: "Best online store to buy right now, fashion trends that matter. Reviews & clothes: Women's, Men's, Old, Young, children.",
    cover: "./images/slider/slide-2.png",
  },
  {
    id: 3,
    title: "50% Off For Your First Shopping",
    desc: "Best online store to buy right now, fashion trends that matter. Reviews & clothes: Women's, Men's, Old, Young, children.",
    cover: "./images/slider/slide-3.png",
  },
  {
    id: 4,
    title: "50% Off For Your First Shopping",
    desc: "Best online store to buy right now, fashion trends that matter. Reviews & clothes: Women's, Men's, Old, Young, children.",
    cover: "./images/slider/slide-4.png",
  },
]

export default SliderData