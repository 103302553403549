import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "../App.css";
import ProductCard from "./ProductCard";
import "react-loading-skeleton/dist/skeleton.css";
import TablePagination from "@mui/material/TablePagination";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function getProductsUrl(initialUrl, cursorPaginatedUrl, orderBy, pageSize) {
  const url = new URL(
    cursorPaginatedUrl === null
      ? initialUrl
      : cursorPaginatedUrl
  );

  url.searchParams.set("ordering", orderBy);
  url.searchParams.set("page_size", pageSize);

  return url.toString();
}

const Products = () => {
  let url = "https://slow-and-buggy.stepchange.lol/api/spree/products";

  console.log("Products hit 44");
  const [products, setProducts] = useState({
    next: null,
    results: [],
    previous: null,
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({
    url: null,
    number: 0,
  });
  const [pageSize, setPageSize] = useState(25);
  const [orderBy, setOrderBy] = useState("");

  useEffect(() => {
    let loadProducts = true;

    const getProducts = async () => {
      try {
        setLoading(true);
        console.log("Fetching products...");

        const productsUrl = getProductsUrl(url, page.url, orderBy, pageSize);

        const res = await fetch(productsUrl, { mode: "cors" });

        if (!res.ok) {
          console.log("Error fetching products full res is :", res);
          throw new Error(`Error: ${res.status} - ${res.statusText}`);
        }

        const data = await res.json();
        if (loadProducts) {
          console.log("Products fetched successfully:", data);
          setProducts(data);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        if (loadProducts) {
          setLoading(false);
        }
      }
    };

    getProducts();

    // Cleanup function
    return () => {
      loadProducts = false;
    };
  }, [url, page, pageSize, orderBy]);

  const Loading = () => {
    return (
      <>
        <div className="col4 product">
          <Skeleton height={350} />
        </div>
        <div className="col4 product">
          <Skeleton height={350} />
        </div>
        <div className="col4 product">
          <Skeleton height={350} />
        </div>
        <div className="col4 product">
          <Skeleton height={350} />
        </div>
        <div className="col4 product">
          <Skeleton height={350} />
        </div>
        <div className="col4 product">
          <Skeleton height={350} />
        </div>
        <div className="col4 product">
          <Skeleton height={350} />
        </div>
        <div className="col4 product">
          <Skeleton height={350} />
        </div>
        <div className="col4 product">
          <Skeleton height={350} />
        </div>
        <div className="col4 product">
          <Skeleton height={350} />
        </div>
        <div className="col4 product">
          <Skeleton height={350} />
        </div>
        <div className="col4 product">
          <Skeleton height={350} />
        </div>
      </>
    );
  };

  const handlePageChange = (e, newPage) => {
    let newUrl = (
      newPage > page.number ? products.next : products.previous
    );
    if (newUrl === null) {
      newUrl = url;
    }
    setPage({
      url: newUrl,
      number: newPage,
    });
  };

  const handleChangeOrderBy = (e) => {
    // Whenever order by changes we also need to reset back to page 0
    setPage({number: 0, url: null});
    setOrderBy(e.target.value);
  };

  const handlePageSizeChange = (e) => {
    // do not change page size
  };

  const orderByOptions = {
    "": "Default",
    "slug": "Slug: asc",
    "-slug": "Slug: desc",
    "created_at": "Creation date: asc",
    "-created_at": "Creation date: desc",
    "average_rating": "Rating: asc",
    "-average_rating": "Rating: desc",
  };

  const itemCount = (
    products.next === null
      ? page.number * pageSize + products.results.length
      : -1
  );

  return (
    <div className="container">
      <div className="content">
        <h2 className="title">Featured Products</h2>

        <div className="row pagination">
        </div>

        <div className="row ordering">
          <div className="col8">
            <TablePagination
              disabled={pageSize === 0}
              count={itemCount}
              page={page.number}
              variant="outlined"
              shape="rounded"
              size="large"
              rowsPerPage={pageSize}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handlePageSizeChange}
            />
          </div>

          <div className="col4">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Order by</InputLabel>
              <Select
                value={orderBy}
                label="Age"
                onChange={handleChangeOrderBy}
              >
                {Object.entries(orderByOptions).map(([value, label]) => (
                  <MenuItem value={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="row products">
          {loading ? (
            <Loading />
          ) : (
            // "Loaded products!"
            products.results.map((product) => (
              <ProductCard product={product} key={product.id} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Products;
